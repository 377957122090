import React from "react"
import SEO from "../components/seo"
import TopBanner from "../components/common/top-banner/top-banner"
import { graphql } from "gatsby"
import ExtendedContent from "../components/extendedContent"
import { setTopColors, toggleSubmenu } from "../state/app"
import { connect } from "react-redux"
import { saveUrlsToLocalStorage } from "../components/common/type-to-url-match/type-to-url-match"

class GetStartedPage extends React.Component {


  componentDidMount() {
    const { dispatch, pageContext } = this.props
    const topColors = this.props.data.entries.archeeTopKolory
    dispatch(setTopColors(topColors))
    dispatch(toggleSubmenu(false))
    saveUrlsToLocalStorage(pageContext)
  }

  render() {
    const { data } = this.props
    const entries = data.entries.nodes?.[0] || ""
    const globals = data.globalSets.nodes?.[0] || ""
    return (
      <>
        <TopBanner banner={entries.archeeBaner} topColors={entries.archeeTopKolory}/>
        <ExtendedContent data={entries.archeeTrescPodstrony} globals={globals}/>
      </>
    )
  }
}

export default connect(state => ({
  topColors: state.app.topColors,
  isSubmenuVisible: state.app.isSubmenuVisible,
}), null)(GetStartedPage)

export const query = graphql`
    query($remoteId: ID, $langKey: String) {
            seo: allCraftArcheeGetStartedArcheeGetStartedEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    ...seoGetStarted
                }
            }
            entries: allCraftArcheeGetStartedArcheeGetStartedEntry(filter: {remoteId: {eq: $remoteId}, language: {eq: $langKey}}) {
                nodes{
                    title,
                    archeeTopKolory,
                    archeeBaner {
                        ...banner,
                    }
                    archeeTrescPodstrony {
                        __typename
                        ...margines,
                        ...naglowek,
                        ...naglowekIOpis,
                        #                    ...component,
                        ...filmLink,
                        ...przycisk
                        ...modulyGraficzne
                        ...on Craft_archeeTrescPodstrony_naglowek2_BlockType {
                            naglowek2
                        }
                        ...on Craft_archeeTrescPodstrony_wybierzCaseStudy_BlockType{
                            wybierzCaseStudy{
                                __typename
                                ...on Craft_archeeCaseStudy_archeeCaseStudy_Entry{
                                    title
                                    slug
                                    uri
                                    __typename
                                    archeeCaseStudyWizytowka{
                                        ...on Craft_archeeCaseStudyWizytowka_wizytowka_BlockType{
                                            zdjecieWTle{
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    seoTitle
                }
            }
            globalSets: allCraftArcheePowiadomieniaGlobalSet(filter: {language: {eq: $langKey}}) {
                nodes {
                    archeeWiadomoscZostalaWyslana {
                        ... on Craft_archeeWiadomoscZostalaWyslana_powiadomienie_BlockType {
                            id
                            naglowek
                            opis
                            przyciskNapis
                            enabled
                        }
                    }
                }
            }
    }`

export function Head({ data }) {
    const seoData = data?.seo?.nodes?.[0] || [];
    return (
        <SEO seoData={seoData}/>
    )
}